import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import TopQuote from "../components/PickYourHome/TopQuote"
import HomeSections from "../components/PickYourHome/HomeSections"
import ChooseFeatures from "../components/PickYourHome/ChooseFeatures"

const PickYourHome = props => {
  const {
    seoInfo,
    topQuote,
    homeSections,
    allHomes,
    allDetails,
    allCost,
    allBuilders,
    allTypes,
  } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <TopQuote topQuote={topQuote} />
      <HomeSections data={homeSections} />
      <ChooseFeatures
        allHomes={allHomes}
        details={allDetails}
        cost={allCost}
        builders={allBuilders}
        types={allTypes}
      />
    </Layout>
  )
}

export const pickYourHomeQuery = graphql`
  query pickYourHomePage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    topQuote: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_toqu_title
        _coo_toqu_content
      }
    }

    homeSections: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hosec_estate_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hosec_estate_title
        _coo_hosec_estate_content
        _coo_hosec_single_family_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hosec_single_family_title
        _coo_hosec_single_family_content
        _coo_hosec_attached_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }

        _coo_hosec_master_title
        _coo_hosec_master_content
        _coo_hosec_master_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }

        _coo_hosec_attached_title
        _coo_hosec_attached_content
        _coo_hosec_quick_possession_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hosec_quick_possession_title
        _coo_hosec_quick_possession_content
      }
    }

    allHomes: allWordpressWpHomes {
      edges {
        node {
          wordpress_id
          title
          home_type
          home_details
          builder
          cost
          slug
          acf {
            _coo_hopst_homes_excerpt
            _coo_hopst_home_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    allDetails: allWordpressWpHomeDetails {
      edges {
        node {
          wordpress_id
          name
        }
      }
    }
    allCost: allWordpressWpCost {
      edges {
        node {
          wordpress_id
          name
        }
      }
    }
    allBuilders: allWordpressWpBuilder {
      edges {
        node {
          wordpress_id
          name
        }
      }
    }
    allTypes: allWordpressWpHomeType {
      edges {
        node {
          name
          wordpress_id
        }
      }
    }
  }
`

export default PickYourHome
