import React, { Component } from "react"
import styled from "styled-components"
import BGImage from "gatsby-background-image"
import { Link } from "gatsby"

import detailsFilter from "./Filters/detailsFilter"
import typeFilter from "./Filters/typeFilter"
import builderFilter from "./Filters/builderFilter"
import costFilter from "./Filters/costFilter"
import {
  colors,
  medWrapper,
  bodyCopyOne,
  standardWrapper,
  fontSizer,
  H1Green,
  B1White,
  buttonThree,
  B1Black,
  H3Black,
} from "../../styles/helpers"

const costAverager = cos => {
  let stratingFrom = ""
  if (cos <= 374) {
    stratingFrom = "mid $300"
  } else if (cos > 374 && cos <= 399) {
    stratingFrom = "high $300"
  } else if (cos > 399 && cos <= 424) {
    stratingFrom = "low $400"
  } else if (cos > 424 && cos <= 474) {
    stratingFrom = "mid $400"
  } else if (cos > 474 && cos <= 499) {
    stratingFrom = "high $400"
  } else if (cos > 499 && cos <= 524) {
    stratingFrom = "low $500"
  } else if (cos > 524 && cos <= 574) {
    stratingFrom = "mid $500"
  } else if (cos > 574 && cos <= 599) {
    stratingFrom = "high $500"
  } else if (cos > 599 && cos <= 624) {
    stratingFrom = "low $600"
  } else if (cos > 624 && cos <= 674) {
    stratingFrom = "mid $600"
  } else if (cos > 674 && cos <= 699) {
    stratingFrom = "high $600"
  } else if (cos > 699) {
    stratingFrom = "$700"
  }

  return stratingFrom
}

const ChooseFeaturesSection = styled.section`
  .intro {
    ${medWrapper};
    padding: 5rem 2rem 0;
    text-align: center;

    div {
      width: 100%;
    }

    h2 {
      ${H1Green};
    }
  }

  .filterContainer {
    ${medWrapper};
  }

  .optionsFilterSection {
    width: 100%;
    padding: 1rem 0 2rem;
  }

  .optionsContainer {
    ${standardWrapper};

    .homePicker {
      border: none;
      width: 100%;

      @media (min-width: 768px) {
        width: calc(33.33%);
      }

      @media (min-width: 1025px) {
        width: calc(33.33%);
      }

      label {
        ${B1White};
        display: block;
        width: 100%;
        padding: 0.5rem;
        background: ${colors.colorSecondary};
        text-align: center;
      }

      select {
        ${bodyCopyOne};
        display: block;
        width: 100%;
        padding: 0.5rem;
        background: ${colors.grey};
        text-align: center;

        option {
          border: 1px solid red;
        }
      }

      &__clear {
        width: 100%;

        button {
          ${buttonThree};
        }
      }
    }
  }
`

const SingleHomeDisplay = styled.div`
  width: 100%;
  margin: 2rem auto;
  transition: all 0.3s ease-in;
  box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.5);
  background-color: ${colors.grey};

  @media (min-width: 768px) {
    width: calc(50% - 2rem);
    margin: 2rem 1rem;
  }

  @media (min-width: 1025px) {
    width: calc(33.33% - 4rem);
    margin: 2rem;
  }

  &:hover {
    box-shadow: 0.75rem 0.75rem 1.2rem 0.4rem rgba(0, 0, 0, 0.5);
  }

  .homeImage {
    position: relative;
    width: 100%;
    min-height: 27.5rem;

    div {
      width: 100%;
      height: 100%;
      position: static !important;
      background-position: bottom center;
      background-repeat: repeat;
      background-size: cover;
      z-index: -1;
    }
  }

  .homeType {
    padding: 2rem 4rem 1rem;

    &.AttachedHomes {
      border-top: 0.3rem solid ${colors.colorShad};
    }

    &.SingleFamilyHomes {
      border-top: 0.3rem solid ${colors.colorSecondary};
    }

    &.EstateHomes {
      border-top: 0.3rem solid ${colors.colorPrimary};
    }

    p {
      ${H3Black};
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }

  .homeDetails {
    padding: 0 4rem;
    p {
      ${H3Black};
      margin-top: 0;

      span {
        display: block;
      }
    }
  }

  .homeBuilder {
    padding: 0 4rem;
    p {
      ${B1Black};
      margin-bottom: 0;
    }
  }

  .homeCost {
    padding: 0 4rem;
    border-bottom: solid 0.2rem ${colors.colorSecondary};

    &.AttachedHomes {
      border-bottom: 0.3rem solid ${colors.colorShad};
    }

    &.SingleFamilyHomes {
      border-bottom: 0.3rem solid ${colors.colorSecondary};
    }

    &.EstateHomes {
      border-bottom: 0.3rem solid ${colors.colorPrimary};
    }

    p {
      ${B1Black};
    }

    .show-home-contact {
      a {
        ${B1Black};
        ${fontSizer(1.4, 1.4, 76.8, 150, 1.6)};

        &:hover {
          color: ${colors.colorPrimary};
        }
      }
    }
  }

  .homeExcerpt {
    padding: 2rem 4rem;
    p {
      ${B1Black};
      ${fontSizer(1.4, 1.4, 76.8, 150, 1.6)};
      margin-bottom: 0;
    }
  }

  .homeLink {
    padding: 2rem 4rem;

    a {
      ${B1Black};
      ${fontSizer(1.2, 1.8, 76.8, 150, 1.6)}
      text-transform: uppercase;

      span {
        padding-right: 0.5rem;
      }
    }
  }
`

class ChooseFeatures extends Component {
  constructor(props) {
    super(props)

    this.handleUpdateHomesDisplayed = this.handleUpdateHomesDisplayed.bind(this)
    this.handleCostFilter = this.handleCostFilter.bind(this)
    this.handleTypeFilter = this.handleTypeFilter.bind(this)
    this.handleBuilderFilter = this.handleBuilderFilter.bind(this)
    this.handleClearTheFilters = this.handleClearTheFilters.bind(this)

    this.state = {
      homes: props.allHomes ? props.allHomes.edges : [],
      details: props.details ? props.details.edges : [],
      cost: props.cost ? props.cost.edges : [],
      builders: props.builders ? props.builders.edges : [],
      types: props.types ? props.types.edges : [],
      homesDesplayed: [],
      costFilter: [1, 9999999999],
      typeFilter: "All Homes",
      builderFilter: "All builders",
    }
  }

  componentDidMount() {
    this.setState(prevState => {
      return {
        ...prevState,
        homesDesplayed: this.state.homes,
      }
    })
  }

  handleUpdateHomesDisplayed() {
    // Update the displyed through the cost filter
    const homesInRange = this.state.homes.filter(home => {
      const thisHouseCost = this.state.cost.find(cost => {
        if (home.node.cost[0] === cost.node.wordpress_id) return true
        return false
      })

      return (
        parseInt(thisHouseCost.node.name) >= this.state.costFilter[0] &&
        parseInt(thisHouseCost.node.name) <= this.state.costFilter[1]
      )
    })
    // Update the displayed through the type filter
    const homesInType = homesInRange.filter(home => {
      if (this.state.typeFilter === "All Homes") return true

      const thisHouseType = this.state.types.find(type => {
        if (home.node.home_type[0] === type.node.wordpress_id) return true
        return false
      })
      return thisHouseType.node.name === this.state.typeFilter
    })
    // Update the displayed homes through the builder filter
    const homesInBuilder = homesInType.filter(home => {
      if (this.state.builderFilter === "All builders") return true

      const thisHouseBuilder = this.state.builders.find(build => {
        if (home.node.builder[0] === build.node.wordpress_id) return true
        return false
      })
      return thisHouseBuilder.node.name === this.state.builderFilter
    })
    // after we have gone through all filters we set the display
    this.setState(prevState => {
      return {
        ...prevState,
        homesDesplayed: homesInBuilder,
      }
    })
  }

  handleCostFilter(costRange) {
    const costArr = costRange.split(",")
    const minRange = parseInt(costArr[0])
    const maxRange = parseInt(costArr[1])

    this.setState(
      prevState => {
        return {
          ...prevState,
          costFilter: [minRange, maxRange],
        }
      },
      () => this.handleUpdateHomesDisplayed()
    )
  }

  handleTypeFilter(typePicked) {
    this.setState(
      prevState => {
        return {
          ...prevState,
          typeFilter: typePicked,
        }
      },
      () => this.handleUpdateHomesDisplayed()
    )
  }

  handleBuilderFilter(builderPicked) {
    this.setState(
      prevState => {
        return {
          ...prevState,
          builderFilter: builderPicked,
        }
      },
      () => this.handleUpdateHomesDisplayed()
    )
  }

  handleClearTheFilters() {
    document.getElementById("cost-price").value = "0, 99999999999"
    document.getElementById("home-type").value = "All Homes"
    document.getElementById("home-builder").value = "All builders"

    this.setState(prevState => {
      return {
        ...prevState,
        homesDesplayed: this.state.homes,
        costFilter: [1, 9999999999],
        typeFilter: "All Homes",
        builderFilter: "All builders",
      }
    })
  }

  render() {
    const onlycostOpt =
      this.state.typeFilter === "All Homes" &&
      this.state.builderFilter === "All builders"

    const priceOptOne = onlycostOpt
      ? 1
      : this.state.cost.findIndex(price => {
          const thisPrice = parseInt(price.node.name)
          let matches = []
          if (thisPrice >= 0 && thisPrice <= 399) {
            matches = this.state.homesDesplayed.filter(disHome => {
              if (disHome.node.cost[0] === price.node.wordpress_id) return price
            })
          } else {
            return false
          }
          return matches.length > 0
        })

    const priceOptTwo = onlycostOpt
      ? 1
      : this.state.cost.findIndex(price => {
          const thisPrice = parseInt(price.node.name)
          let matches = []
          if (thisPrice >= 400 && thisPrice <= 499) {
            matches = this.state.homesDesplayed.filter(disHome => {
              if (disHome.node.cost[0] === price.node.wordpress_id) return price
            })
          } else {
            return false
          }
          return matches.length > 0
        })

    const priceOptThree = onlycostOpt
      ? 1
      : this.state.cost.findIndex(price => {
          const thisPrice = parseInt(price.node.name)
          let matches = []
          if (thisPrice >= 500 && thisPrice <= 699) {
            matches = this.state.homesDesplayed.filter(disHome => {
              if (disHome.node.cost[0] === price.node.wordpress_id) return price
            })
          } else {
            return false
          }
          return matches.length > 0
        })

    const priceOptFour = onlycostOpt
      ? 1
      : this.state.cost.findIndex(price => {
          const thisPrice = parseInt(price.node.name)
          let matches = []
          if (thisPrice >= 700 && thisPrice <= 9999999999) {
            matches = this.state.homesDesplayed.filter(disHome => {
              if (disHome.node.cost[0] === price.node.wordpress_id) return price
            })
          } else {
            return false
          }
          return matches.length > 0
        })

    const onlyTypeOpt =
      this.state.costFilter[0] === 1 &&
      this.state.costFilter[1] === 9999999999 &&
      this.state.builderFilter === "All builders"

    const onlyBuilderOpt =
      this.state.costFilter[0] === 1 &&
      this.state.costFilter[1] === 9999999999 &&
      this.state.typeFilter === "All Homes"

    return (
      <ChooseFeaturesSection id="perfectHome">
        <div className="wrapper">
          <div className="intro">
            <div>
              <h2>Find your Perfect Home</h2>
            </div>
          </div>
          {/* <div className="optionsFilterSection">
            <div className="optionsContainer">
              <fieldset className="homePicker homePicker__clear">
                <button onClick={this.handleClearTheFilters}>
                  Clear Filters
                </button>
              </fieldset>
              <fieldset className="homePicker homePicker__price">
                <label>Choose By Price</label>
                <select
                  // onClick={() => this.handleCostFilter("0, 99999999999")}
                  onChange={e => {
                    this.handleCostFilter(e.currentTarget.value)
                  }}
                  name="costs"
                  id="cost-price"
                >
                  <option value="0, 99999999999">
                    --Please choose an range--
                  </option>
                  <option
                    value="0,399"
                    disabled={priceOptOne === -1 ? true : false}
                  >
                    Below $400,000
                  </option>
                  <option
                    value="400,499"
                    disabled={priceOptTwo === -1 ? true : false}
                  >
                    $400,000 - $499,000
                  </option>
                  <option
                    value="500,699"
                    disabled={priceOptThree === -1 ? true : false}
                  >
                    $500,000 - $699,000
                  </option>
                  <option
                    value="700, 99999999999"
                    disabled={priceOptFour === -1 ? true : false}
                  >
                    $700,000 +{" "}
                  </option>
                  <option value="0, 99999999999">
                    -- Reset Price Range --
                  </option>
                </select>
              </fieldset>
              <fieldset className="homePicker homePicker__type">
                <label>Choose By Home Type</label>
                <select
                  onChange={e => {
                    this.handleTypeFilter(e.currentTarget.value)
                  }}
                  name="types"
                  id="home-type"
                >
                  <option value="All Homes">--Please choose a type--</option>
                  {this.state.types.map(type => {
                    let isActive
                    if (onlyTypeOpt) {
                      isActive = 1
                    } else {
                      isActive = this.state.homesDesplayed.findIndex(
                        disHome => {
                          if (
                            disHome.node.home_type[0] === type.node.wordpress_id
                          )
                            return true
                          return false
                        }
                      )
                    }
                    return (
                      <option
                        key={type.node.wordpress_id}
                        value={type.node.name}
                        disabled={isActive === -1 ? true : false}
                      >
                        {type.node.name}
                      </option>
                    )
                  })}
                  <option value="All Homes">-- Reset / All types --</option>
                </select>
              </fieldset>
              <fieldset className="homePicker homePicker__builder">
                <label>Choose By Builder</label>
                <select
                  onChange={e => {
                    this.handleBuilderFilter(e.currentTarget.value)
                  }}
                  name="builders"
                  id="home-builder"
                >
                  <option value="All builders">
                    --Please choose a Builder--
                  </option>
                  {this.state.builders.map(builder => {
                    let isActive

                    if (onlyBuilderOpt) {
                      isActive = 1
                    } else {
                      isActive = this.state.homesDesplayed.findIndex(
                        disHome => {
                          if (
                            disHome.node.builder[0] ===
                            builder.node.wordpress_id
                          )
                            return true
                          return false
                        }
                      )
                    }
                    return (
                      <option
                        key={builder.node.wordpress_id}
                        value={builder.node.name}
                        disabled={isActive === -1 ? true : false}
                      >
                        {builder.node.name}
                      </option>
                    )
                  })}
                  <option value="All builders">
                    -- Reset / All Builders --
                  </option>
                </select>
              </fieldset>
            </div>
          </div> */}
          <div className="filterContainer">
            {this.state.homesDesplayed.map((home, index) => {
              const typeName = this.state.types.find(allType => {
                const thisHomeTyep = home.node.home_type.find(type => {
                  if (type === allType.node.wordpress_id) return type
                })

                if (thisHomeTyep) return true
              })

              const typeClass = typeName.node.name.split(" ").join("")

              return (
                <SingleHomeDisplay key={index}>
                  <Link
                    to={`/pick-your-home/${home.node.slug}`}
                    className="boxLink"
                  >
                    <div className="homeImage">
                      <BGImage
                        tag="div"
                        fluid={
                          home?.node?.acf?._coo_hopst_home_image?.localFile
                            ?.childImageSharp?.fluid
                        }
                      />
                    </div>
                    <div className={`homeType ${typeClass}`}>
                      <p>
                        {home.node.home_type.map((type, index) => (
                          <span key={index}>
                            {typeFilter(type, this.state.types).map((ty, i) => (
                              <span key={i}>{ty}</span>
                            ))}
                          </span>
                        ))}
                      </p>
                    </div>

                    <div className="homeDetails">
                      <p>
                        {home.node.home_details.map((detail, index) => (
                          <span key={index}>
                            {detailsFilter(detail, this.state.details).map(
                              (det, i) => (
                                <span key={i}>{`${det} `}</span>
                              )
                            )}
                          </span>
                        ))}
                      </p>
                    </div>
                    <div className="homeBuilder">
                      <p>
                        {home.node.builder.map((build, index) => (
                          <span key={index}>
                            {builderFilter(build, this.state.builders).map(
                              (bud, i) => (
                                <span key={i}>{bud} </span>
                              )
                            )}
                          </span>
                        ))}
                      </p>
                    </div>

                    <div className={`homeCost ${typeClass}`}>
                      {/* <p>
                        Starting from the{" "}
                        {home.node.cost.map((price, index) => (
                          <span key={index}>
                            {costFilter(price, this.state.cost).map(
                              (cos, i) => {
                                const stratingFrom = costAverager(cos)
                                return <span key={i}>{stratingFrom}s </span>
                              }
                            )}
                          </span>
                        ))}
                      </p> */}
                      <p className="show-home-contact">
                        <Link to="/show-homes">
                          Contact Show Homes for Pricing
                        </Link>
                      </p>
                    </div>
                    <div className="homeLink">
                      <Link to={`/pick-your-home/${home.node.slug}`}>
                        <span>&gt;</span>View More
                      </Link>
                    </div>
                  </Link>
                </SingleHomeDisplay>
              )
            })}
            {this.state.homesDesplayed.length <= 0 && (
              <div>
                <div>
                  <p>
                    No results for your search. Please clear the filters and try
                    again
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </ChooseFeaturesSection>
    )
  }
}

export default ChooseFeatures
