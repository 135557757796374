import React from "react"
import BgImg from "gatsby-background-image"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  medWrapper,
  colors,
  H2White,
  B1White,
  fontSizer,
} from "../../styles/helpers"

const HomeSectionsStyled = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .homeSec {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 1rem 0;
    transition: all 0.3s ease-in;
    box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.5);

    &:hover {
      box-shadow: 0.75rem 0.75rem 1.2rem 0.4rem rgba(0, 0, 0, 0.5);
    }

    &__image {
      position: relative;
      width: 100%;
      min-height: 30rem;

      @media (min-width: 768px) {
        width: 60%;
        min-height: 40rem;
      }

      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: repeat;
        background-size: cover;
      }
    }

    &__content {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 4.5rem 3rem;

      @media (min-width: 768px) {
        width: 40%;
        padding: 9rem 4rem;
      }

      &--inner {
        align-self: center;
        width: 100%;
      }

      &--title {
        padding-bottom: 3rem;
        margin-bottom: 3rem;
        border-bottom: 0.1rem solid ${colors.white};

        h2 {
          ${H2White};
          margin: 0;
        }
      }

      p {
        ${B1White};
      }

      a {
        ${B1White};
        ${fontSizer(1.2, 1.8, 76.8, 150, 1.6)}
        text-transform: uppercase;

        span {
          padding-right: 0.5rem;
        }
      }
    }
  }

  .estateHomes {
    .homeSec__content {
      background-color: ${colors.colorTertiary};
    }
  }

  .singleHomes {
    .homeSec__content {
      background-color: ${colors.colorShad};
    }
  }

  .masterMainHomes {
    .homeSec__content {
      background-color: ${colors.colorPrimary};
    }
  }

  .attachedHomes {
    .homeSec__content {
      background-color: ${colors.colorSecondary};
    }
  }

  .quickHomes {
    .homeSec__content {
      background-color: ${colors.greyBrown};
    }
  }
`

const HomeSections = ({ data: { acf } }) => {
  console.log("acf", acf)
  const estatesFluid =
    acf._coo_hosec_estate_image.localFile.childImageSharp.fluid
  const estatesTitle = acf._coo_hosec_estate_title
  const estatesContent = acf._coo_hosec_estate_content

  const singleFluid =
    acf._coo_hosec_single_family_image.localFile.childImageSharp.fluid
  const singleTitle = acf._coo_hosec_single_family_title
  const singleContent = acf._coo_hosec_single_family_content

  const masterFluid =
    acf._coo_hosec_master_image.localFile.childImageSharp.fluid
  const masterTitle = acf._coo_hosec_master_title
  const masterContent = acf._coo_hosec_master_content

  const attachedFluid =
    acf._coo_hosec_attached_image.localFile.childImageSharp.fluid
  const attachedTitle = acf._coo_hosec_attached_title
  const attachedContent = acf._coo_hosec_attached_content

  const quickFluid =
    acf._coo_hosec_quick_possession_image.localFile.childImageSharp.fluid
  const quickTitle = acf._coo_hosec_quick_possession_title
  const quickContent = acf._coo_hosec_quick_possession_content

  return (
    <HomeSectionsStyled id="pickByType">
      <div className="wrapper">
        <Link to="/pick-your-home/estate-homes" className="estateHomes homeSec">
          <div className="homeSec__image">
            <BgImg tag="div" fluid={estatesFluid} />
          </div>
          <div className="homeSec__content">
            <div className="homeSec__content--inner">
              <div className="homeSec__content--title">
                <h2>{estatesTitle}</h2>
              </div>
              <div>
                {/* <p>{estatesContent}</p> */}
                <p>
                  <Link to="/show-homes">Contact Show Homes for Pricing</Link>
                </p>
              </div>
              <div>
                <Link to="/pick-your-home/estate-homes">
                  <span>&gt;</span>Click to view all
                </Link>
              </div>
            </div>
          </div>
        </Link>

        <Link
          to="/pick-your-home/single-family-homes"
          className="singleHomes homeSec"
        >
          <div className="homeSec__image">
            <BgImg tag="div" fluid={singleFluid} />
          </div>
          <div className="homeSec__content">
            <div className="homeSec__content--inner">
              <div className="homeSec__content--title">
                <h2>{singleTitle}</h2>
              </div>
              <div>
                {/* <p>{singleContent}</p> */}
                <p>
                  <Link to="/show-homes">Contact Show Homes for Pricing</Link>
                </p>
              </div>
              <div>
                <Link to="/pick-your-home/single-family-homes">
                  <span>&gt;</span>Click to view all
                </Link>
              </div>
            </div>
          </div>
        </Link>

        <Link
          to="/pick-your-home/master-on-main-bungalow-villas"
          className="masterMainHomes homeSec"
        >
          <div className="homeSec__image">
            <BgImg tag="div" fluid={masterFluid} />
          </div>
          <div className="homeSec__content">
            <div className="homeSec__content--inner">
              <div className="homeSec__content--title">
                <h2>{masterTitle}</h2>
              </div>
              <div>
                {/* <p>{masterContent}</p> */}
                <p>
                  <Link to="/show-homes">Contact Show Homes for Pricing</Link>
                </p>
              </div>
              <div>
                <Link to="/pick-your-home/master-on-main-bungalow-villas">
                  <span>&gt;</span>Click to view all
                </Link>
              </div>
            </div>
          </div>
        </Link>

        <Link
          to="/pick-your-home/attached-homes"
          className="attachedHomes homeSec"
        >
          <div className="homeSec__image">
            <BgImg tag="div" fluid={attachedFluid} />
          </div>
          <div className="homeSec__content">
            <div className="homeSec__content--inner">
              <div className="homeSec__content--title">
                <h2>{attachedTitle}</h2>
              </div>
              <div>
                {/* <p>{attachedContent}</p> */}
                <p>
                  <Link to="/show-homes">Contact Show Homes for Pricing</Link>
                </p>
              </div>
              <div>
                <Link to="/pick-your-home/attached-homes">
                  <span>&gt;</span>Click to view all
                </Link>
              </div>
            </div>
          </div>
        </Link>

        <Link
          to="/pick-your-home/quick-possessions"
          className="quickHomes homeSec"
        >
          <div className="homeSec__image">
            <BgImg tag="div" fluid={quickFluid} />
          </div>
          <div className="homeSec__content">
            <div className="homeSec__content--inner">
              <div className="homeSec__content--title">
                <h2>{quickTitle}</h2>
              </div>
              <div>
                <p>{quickContent}</p>
              </div>
              <div>
                <Link to="/pick-your-home/quick-possessions">
                  <span>&gt;</span>Click to view all
                </Link>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </HomeSectionsStyled>
  )
}

export default HomeSections
